// Helper function to build API URLs
export function buildApiUrl(endpoint) {
    return `https://merrouchgaming.live/api${endpoint}`; // Ensure you're using HTTPS
}

// Get common headers for API requests
export function getAuthHeaders() {
    return new Headers({
        'Authorization': 'Basic ' + btoa('admin:Mg200723@l') // Your API credentials
    });
}

export const validateUserCredentials = async (username, password) => {
    const apiUrl = buildApiUrl(`/users/${encodeURIComponent(username)}/${encodeURIComponent(password)}/valid`);
    
    console.log('Fetching API URL:', apiUrl);

    try {
        const response = await fetch(apiUrl, { headers: getAuthHeaders() });

        if (!response.ok) {
            console.error(`HTTP Error: ${response.status}`); // Log HTTP error
            throw new Error(`HTTP status ${response.status}`);
        }

        const data = await response.json();
        console.log('API Response Body:', data); // Log the full API response

        if (data && data.result && data.result.result === 0) {
            return {
                isValid: true,
                userId: data.result.identity.userId,
                username: data.result.identity.name
            };
        } else {
            return { isValid: false };
        }
    } catch (error) {
        console.error('Error in API call:', error);
        return { isValid: false }; // Handle errors
    }
};


// Fetch user ID
export const fetchUserId = async (username) => {
    try {
        console.log('API Call - fetching user ID for:', username);
        const response = await fetch(buildApiUrl(`/users/${username}`), { headers: getAuthHeaders() });
        const data = await response.json();
        console.log('Fetched user ID:', data.userId);
        return data.userId;
    } catch (error) {
        console.error('Error in fetchUserId:', error);
        return null; // Return null if something goes wrong
    }
};

// Fetch user profile picture
export async function fetchUserProfilePicture(userId) {
    const apiUrl = buildApiUrl(`/users/${userId}/picture`);
    try {
        const response = await fetch(apiUrl, { headers: getAuthHeaders() });
        if (!response.ok) throw new Error('Error fetching user picture');

        const contentType = response.headers.get('content-type');
        if (contentType?.includes('application/json')) {
            return null; // No image available
        } else {
            const blob = await response.blob();
            return blob.size === 0 ? null : URL.createObjectURL(blob);
        }
    } catch (error) {
        console.error('Error fetching user picture:', error);
        return null; // Error occurred
    }
}

// Fetch user points
export async function fetchUserPoints(userId) {
    const apiUrl = buildApiUrl(`/users/${userId}/points`);
    try {
        const response = await fetch(apiUrl, { headers: getAuthHeaders() });
        if (!response.ok) throw new Error('Network response was not ok');
        const data = await response.json();
        return data && !data.isError ? data.result : null; // Return points or null
    } catch (error) {
        console.error('Error fetching user points:', error);
        return null; // Error occurred
    }
}

// Fetch active user sessions
export async function fetchActiveUserSessions() {
    const apiUrl = buildApiUrl('/usersessions/active');
    try {
        const response = await fetch(apiUrl, { headers: getAuthHeaders() });
        if (!response.ok) throw new Error('Error fetching active sessions');
        const data = await response.json();
        return data.result || [];
    } catch (error) {
        console.error('Error fetching active user sessions:', error);
        return [];
    }
}

// Fetch user by ID
export async function fetchUserById(userId) {
    const apiUrl = buildApiUrl(`/users/${userId}`);
    try {
        const response = await fetch(apiUrl, { headers: getAuthHeaders() });
        console.log(`Fetching user from ${apiUrl}`);
        if (!response.ok) throw new Error('Error fetching user by ID');
        const data = await response.json();
        console.log('User data:', data);
        return data.result || null; // Assuming 'result' contains the user data
    } catch (error) {
        console.error('Error fetching user by ID:', error);
        return null; // Handle errors appropriately
    }
}

// Fetch user balance
export async function fetchUserBalance(userId) {
    const apiUrl = buildApiUrl(`/users/${userId}/balance`);
    try {
        const response = await fetch(apiUrl, { headers: getAuthHeaders() });
        if (!response.ok) throw new Error('Network response was not ok');
        const data = await response.json();
        const totalHours = data.result.availableTime / 3600;
        const hours = Math.floor(totalHours);
        const minutes = Math.floor((totalHours - hours) * 60);
        
        return data.result.availableTime <= 0 ? 'No Time Left' : `${hours}h : ${minutes} min`;
    } catch (error) {
        console.error('Error fetching user balance:', error);
        return 'Error fetching time'; // Handle errors appropriately
    }
}

// upload a picture 
// upload a picture 
export async function uploadProfilePicture(file) {
    // Retrieve userId from sessionStorage
    const userId = localStorage.getItem('userId');

    if (!userId) {
        throw new Error('User ID is not defined');
    }

    const apiUrl = buildApiUrl(`/users/${userId}/picture`);
    const headers = getAuthHeaders();
    headers.set('Content-Type', 'application/octet-stream');

    const reader = new FileReader();
    return new Promise((resolve, reject) => {
        reader.onloadend = async function () {
            const byteArray = new Uint8Array(reader.result);
            try {
                const response = await fetch(apiUrl, {
                    method: 'PUT',
                    headers: headers,
                    body: byteArray
                });
                if (response.ok) {
                    console.log('Profile picture uploaded successfully');
                    resolve();
                } else {
                    const errorMessage = await response.text();
                    reject(new Error(`Error uploading picture: ${errorMessage}`));
                }
            } catch (error) {
                console.error('Error uploading profile picture:', error);
                reject(error);
            }
        };
        reader.readAsArrayBuffer(file);
    });
}

export const updateUserInfo = async (userInfo) => {
    try {
        // Get the user ID from local storage
        const userId = localStorage.getItem('userId');
        if (!userId) {
            console.error("User ID is not found in local storage.");
            return null;
        }

        // Build the query string with user details
        const queryParams = new URLSearchParams({
            UserId: userId,
            Username: userInfo.username || '',
            Email: userInfo.email || '',
            FirstName: userInfo.firstName || '',
            LastName: userInfo.lastName || '',
            MobilePhone: userInfo.mobilePhone || ''
        });

        // Construct the full API URL
        const url = `${buildApiUrl('/users')}?${queryParams.toString()}`;

        // Send the POST request
        const response = await fetch(url, {
            method: 'POST',
            headers: getAuthHeaders(),
            body: '' // Empty body as required by the API
        });

        if (response.ok) {
            const data = await response.json();
            console.log("User info updated successfully:", data);
            return data;
        } else {
            console.error("Failed to update user info:", response.status);
            return null;
        }
    } catch (error) {
        console.error("Error in updateUserInfo:", error);
        return null;
    }
};
