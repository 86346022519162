import React, { useEffect, useState } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import Login from './components/Login';
import Welcome from './components/Welcome';

const App = () => {
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [userId, setUserId] = useState(''); // Add state for userId

    useEffect(() => {
        const storedUsername = localStorage.getItem('username');
        const storedUserId = localStorage.getItem('userId'); // Retrieve userId

        if (storedUsername && storedUserId) {
            setUsername(storedUsername);
            setUserId(storedUserId); // Set userId in state
            navigate('/welcome');
        }
    }, [navigate]);

    const handleLogout = () => {
        localStorage.removeItem('username');
        localStorage.removeItem('userId'); // Clear userId from localStorage
        setUsername('');
        setUserId(''); // Clear userId state
        navigate('/');
    };

    return (
        <Routes>
            <Route path="/" element={<Login setUsername={setUsername} />} />
            <Route path="/welcome" element={<Welcome username={username} userId={userId} handleLogout={handleLogout} />} />
        </Routes>
    );
};

export default App;
