import React, { useEffect, useState } from 'react';
import { buildApiUrl, getAuthHeaders } from '../utils/api';
import './UserBalance.css'; // Import the CSS file

const UserBalance = ({ userId }) => {
    const [balance, setBalance] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchUserBalance = async () => {
            const apiUrl = buildApiUrl(`/users/${userId}/balance`);
            try {
                const response = await fetch(apiUrl, { headers: getAuthHeaders() });
                if (!response.ok) throw new Error('Network response was not ok');

                const data = await response.json();
                const totalMinutes = data.result.availableTime / 60; // Total available time in minutes

                if (totalMinutes <= 0) {
                    setBalance(<span className="error-message">You have no time left</span>);
                } else {
                    // Check the total minutes first for color coding
                    if (totalMinutes < 60) { // Less than 60 minutes
                        setBalance(
                            <span style={{ color: 'red' }}>
                                Your available time is {totalMinutes} min
                            </span>
                        );
                    } else { // 60 minutes or more
                        const hours = Math.floor(totalMinutes / 60);
                        const minutes = Math.floor(totalMinutes % 60);
                        setBalance(
                            <span style={{ color: 'green' }}>
                                Your available time is {hours}h : {minutes} min
                            </span>
                        );
                    }
                }
            } catch (error) {
                console.error('Error fetching user balance:', error);
                setBalance(<span className="error-message">Error fetching time data.</span>);
            } finally {
                setLoading(false);
            }
        };

        fetchUserBalance();
    }, [userId]);

    return (
        <div className="balance-container"> {/* Apply the CSS class here */}
            {loading ? (
                <p>Loading balance...</p>
            ) : (
                <p id="balanceDisplay">{balance}</p>
            )}
        </div>
    );
};

export default UserBalance;
