import React, { forwardRef } from 'react'; // Ensure this import is correct
import './Sidebar.css';
import ProfilePicture from './ProfilePicture';

const Sidebar = forwardRef(({ activeTab, handleTabClick, handleLogout, username, isOpen, toggleSidebar, refreshProfilePicture }, ref) => {
    return (
        <div className={`sidebar ${isOpen ? 'open' : 'closed'}`} ref={ref}>
            <div className="profile-picture-container">
                <ProfilePicture username={username} refresh={refreshProfilePicture} /> {/* Pass refresh state */}
            </div>
            <ul>
                <div className="tab-buttons">
                    <button 
                        className={`tab-button ${activeTab === 'EditDash' ? 'tab-active' : ''}`} 
                        onClick={() => {
                            handleTabClick('EditDash');
                            toggleSidebar(); // Close sidebar after clicking
                        }}
                    >
                        Welcome, {username}
                    </button>
                    <button 
                        className={`tab-button ${activeTab === 'normal' ? 'tab-active' : ''}`} 
                        onClick={() => {
                            handleTabClick('normal');
                            toggleSidebar(); // Close sidebar after clicking
                        }}
                    >
                        Normal PCs
                    </button>
                    <button 
                        className={`tab-button ${activeTab === 'vip' ? 'tab-active' : ''}`} 
                        onClick={() => {
                            handleTabClick('vip');
                            toggleSidebar(); // Close sidebar after clicking
                        }}
                    >
                        VIP PCs
                    </button>
                    <button 
                        className={`tab-button ${activeTab === 'blank' ? 'tab-active' : ''}`} 
                        onClick={() => {
                            handleTabClick('blank');
                            toggleSidebar(); // Close sidebar after clicking
                        }}
                    >
                        BlankContent
                    </button>
                </div>
                <li onClick={handleLogout}>
                    Logout
                </li>
            </ul>
        </div>
    );
});

export default Sidebar; // Ensure the export is correct
