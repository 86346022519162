import React, { useEffect, useState } from 'react';
import { fetchUserById, fetchActiveUserSessions, fetchUserBalance } from '../utils/api';
import './HostComputers.css';

const HostComputers = () => {
    const [computers, setComputers] = useState([]);

    useEffect(() => {
        const computersList = [
            { number: 1, id: 26 },
            { number: 2, id: 12 },
            { number: 3, id: 8 },
            { number: 4, id: 5 },
            { number: 5, id: 17 },
            { number: 6, id: 11 },
            { number: 7, id: 16 },
            { number: 8, id: 14 }
        ];

        const fetchComputersData = async () => {
            const activeSessions = await fetchActiveUserSessions();
            const updatedComputers = await Promise.all(computersList.map(async (computer) => {
                const session = activeSessions.find(s => s.hostId === computer.id);
                let timeLeft = null;

                if (session) {
                    // Fetch user details and balance only if there's an active session
                    const user = await fetchUserById(session.userId);
                    if (user) {
                        const balance = await fetchUserBalance(user.id);
                        timeLeft = balance; // Store the user's available time
                    }
                }

                return {
                    number: computer.number,
                    id: computer.id,
                    isActive: !!session,
                    timeLeft: timeLeft || 'No Time' // Default message if no time
                };
            }));

            setComputers(updatedComputers); // Update state
        };

        fetchComputersData(); // Initial fetch
        const intervalId = setInterval(fetchComputersData, 5000); // Fetch every 5 seconds

        return () => clearInterval(intervalId); // Cleanup on unmount
    }, []); // Runs once on mount

    return (
        <div id="computerGrid" className="computer-grid">
            {computers.map(computer => {
                const timeParts = computer.timeLeft && computer.timeLeft !== 'No Time'
                    ? computer.timeLeft.split(' : ') // Split the time string
                    : [0, 0]; // Default to 0h : 0min

                const hours = parseInt(timeParts[0]) || 0;
                const minutes = parseInt(timeParts[1]) || 0;
                const totalMinutes = hours * 60 + minutes; // Calculate total minutes

                return (
                    <div key={computer.id} className={`pc-square ${computer.isActive ? (totalMinutes < 60 ? 'orange' : 'active') : 'inactive'}`}>
                        <strong>PC{computer.number}</strong><br />
                        {computer.isActive 
                            ? `Active - Time Left: ${computer.timeLeft}` 
                            : 'No User'}
                    </div>
                );
            })}
        </div>
    );
};

export default HostComputers;
