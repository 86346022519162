import React, { useEffect, useState } from 'react';
import { fetchUserPoints } from '../utils/api'; // Adjust the import path as necessary
import './UserPoints.css';

const UserPoints = ({ userId }) => {
    const [points, setPoints] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchPoints = async () => {
            setLoading(true);
            const fetchedPoints = await fetchUserPoints(userId);
            setPoints(fetchedPoints);
            setLoading(false);
        };

        fetchPoints();
    }, [userId]);

    return (
        <div className="points-container">
            {loading ? (
                <span className="loading-text">Loading points...</span>
            ) : (
                <>
                    <span className="points-text">Points:</span>
                    <span
                        className={`points-value ${points > 55 ? 'green' : 'red'}`}
                    >
                        {points !== null ? points : 'Error loading points'}
                    </span>
                </>
            )}
        </div>
    );
};

export default UserPoints;
