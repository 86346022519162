import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { validateUserCredentials } from '../utils/api'; // Ensure this is correctly imported
import './Login.css';

const Login = ({ setUsername }) => {
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();
        const username = document.getElementById('username').value;
        const password = document.getElementById('password').value;

        try {
            console.log('Attempting to validate user:', { username, password });
            const data = await validateUserCredentials(username, password); // Call validateUserCredentials
            
            console.log('Validation response:', data);
            
            // Check if the API response indicates the login is valid
            if (data && data.isValid) { // Adjusted check to match API response
                const userId = data.userId; // Extract userId from the response
                const userName = data.username; // Extract username

                localStorage.setItem('username', userName); // Store username in localStorage
                localStorage.setItem('userId', userId); // Store userId in localStorage
                console.log('Stored userId:', userId); // Check what is being stored
                setUsername(userName); // Set username in the state
                
                // Automatically scroll up to the top
                window.scrollTo({ top: 0, behavior: 'smooth' });

                setTimeout(() => {
                    navigate('/welcome'); // Redirect to welcome page after a short delay
                }, 500); // Delay to allow scroll animation to complete
            } else {
                console.log('Invalid credentials response:', data);
                setError('Invalid credentials.'); // Set error message
            }
        } catch (error) {
            console.error('Error validating credentials:', error);
            setError('Error validating credentials.'); // Set error message
        }
    };

    return (
        <div className="login-container">
            <img src="https://merrouchgaming.com/merrouchgaminglogo.png" alt="Merrouch Gaming Logo" className="logo" />
            <h2>Login to Merrouch Gaming</h2>
            <form onSubmit={handleSubmit}>
                <div className="input-group">
                    <label htmlFor="username">Username:</label>
                    <input type="text" id="username" required />
                </div>
                <div className="input-group">
                    <label htmlFor="password">Password:</label>
                    <input type="password" id="password" required />
                </div>
                <button type="submit">Login</button>
                {error && <p className="error">{error}</p>}
            </form>
        </div>
    );
};

export default Login;
