import React, { useState, useEffect } from 'react';
import { uploadProfilePicture, fetchUserProfilePicture, fetchUserById, updateUserInfo } from '../utils/api'; 
import './EditDash.css';
import { ReactComponent as UploadIcon } from './edit.svg'; // Assuming the SVG file is in 'assets'

const EditDash = ({ onProfilePictureUpdate }) => {
    const [profilePicture, setProfilePicture] = useState(null);
    const [currentPictureUrl, setCurrentPictureUrl] = useState(null);
    const [userInfo, setUserInfo] = useState({});
    const [loading, setLoading] = useState(true);
    const [uploading, setUploading] = useState(false);
    const [error, setError] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [originalUserInfo, setOriginalUserInfo] = useState({}); // Store original user info
    const userId = localStorage.getItem('userId');

    useEffect(() => {
        const loadProfileData = async () => {
            setLoading(true);
            if (userId) {
                const url = await fetchUserProfilePicture(userId);
                setCurrentPictureUrl(url || 'https://merrouchgaming.com/placeholder.jpg');

                const fetchedUserInfo = await fetchUserById(userId);
                const userData = {
                    userId: fetchedUserInfo?.id || '',
                    username: fetchedUserInfo?.username || '',
                    email: fetchedUserInfo?.email || '',
                    firstName: fetchedUserInfo?.firstName || '',
                    lastName: fetchedUserInfo?.lastName || '',
                    mobilePhone: fetchedUserInfo?.mobilePhone || ''
                };
                setUserInfo(userData);
                setOriginalUserInfo(userData); // Set original user info
            }
            setLoading(false);
        };
        loadProfileData();
    }, [userId]);

    const handleFileChangeAndUpload = async (event) => {
        const file = event.target.files[0];
        if (file) {
            setProfilePicture(file);
            setUploading(true);
            setError(null);
            try {
                await uploadProfilePicture(file);
                const updatedUrl = await fetchUserProfilePicture(userId);
                setCurrentPictureUrl(updatedUrl || 'https://merrouchgaming.com/placeholder.jpg');
                onProfilePictureUpdate();
                setProfilePicture(null);
            } catch (error) {
                console.error('Upload failed:', error);
                setError('Failed to upload picture. Please try again.');
            } finally {
                setUploading(false);
            }
        } else {
            setError('No file selected for upload.');
        }
    };

    const handleUploadIconClick = () => {
        document.getElementById('fileInput').click();
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setUserInfo((prevInfo) => ({
            ...prevInfo,
            [name]: value
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setError(null);
        try {
            await updateUserInfo(userInfo);
            alert('Profile updated successfully!');
            setIsEditing(false);
        } catch (error) {
            console.error('Error updating profile:', error);
            setError('Failed to update profile. Please try again.');
        }
    };

    const handleCancelEdit = () => {
        setUserInfo(originalUserInfo); // Reset to original user info
        setIsEditing(false); // Exit edit mode
    };

    return (
        <div className="edit-dash">
            <h2>Edit Profile</h2>
            {loading ? (
                <div className="loading-animation">
                    <img src="https://merrouchgaming.com/Loading.gif" alt="Loading..." />
                </div>
            ) : (
                <>
                    {currentPictureUrl && (
                        <div className="current-picture">
                            <img src={currentPictureUrl} alt="Current Profile" />
                            <div className="upload-icon" onClick={handleUploadIconClick}>
                                <UploadIcon />
                            </div>
                        </div>
                    )}
                    {error && <div className="error-message">{error}</div>}
                    {isEditing ? (
                        <div className="user-info">
                            <form onSubmit={handleSubmit}>
                                <div>
                                    <label>Email:</label>
                                    <input 
                                        type="email" 
                                        name="email" 
                                        value={userInfo.email} 
                                        onChange={handleInputChange} 
                                        required
                                    />
                                </div>
                                <div>
                                    <label>First Name:</label>
                                    <input 
                                        type="text" 
                                        name="firstName" 
                                        value={userInfo.firstName} 
                                        onChange={handleInputChange} 
                                    />
                                </div>
                                <div>
                                    <label>Last Name:</label>
                                    <input 
                                        type="text" 
                                        name="lastName" 
                                        value={userInfo.lastName} 
                                        onChange={handleInputChange} 
                                    />
                                </div>
                                <div>
                                    <label>Mobile Phone:</label>
                                    <input 
                                        type="text" 
                                        name="mobilePhone" 
                                        value={userInfo.mobilePhone} 
                                        onChange={handleInputChange} 
                                    />
                                </div>
                                <button type="submit" className="save-btn">Save Changes</button>
                                <button type="button" onClick={handleCancelEdit} className="cancel-btn">Cancel</button>
                            </form>
                        </div>
                    ) : (
                        <div className="user-info">
                            <div><strong>Email:</strong> {userInfo.email}</div>
                            <div><strong>First Name:</strong> {userInfo.firstName}</div>
                            <div><strong>Last Name:</strong> {userInfo.lastName}</div>
                            <div><strong>Mobile Phone:</strong> {userInfo.mobilePhone}</div>
                            <button onClick={() => setIsEditing(true)} className="edit-btn">Edit</button>
                        </div>
                    )}
                    <input 
                        type="file" 
                        id="fileInput" 
                        onChange={handleFileChangeAndUpload} 
                        className="file-input" 
                    />
                </>
            )}
        </div>
    );
};

export default EditDash;
