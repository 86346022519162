import React from 'react';
import './BlankContent.css';

const BlankContent = () => {
    return (
        <div style={{ padding: '20px', textAlign: 'center' }}>
            <h3>No Content Available</h3>
            <p>Please select an option from the menu to see more information.</p>
        </div>
    );
};

export default BlankContent;
