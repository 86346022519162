import React, { useState, useEffect, useRef } from 'react';
import './Welcome.css';
import HostComputers from './HostComputers';
import HostVipComputers from './HostVIPComputers';
import BlankContent from './BlankContent';
import EditDash from './EditDash';
import Sidebar from './Sidebar';

const Welcome = ({ username, handleLogout, points, balance }) => {
    const [activeTab, setActiveTab] = useState('normal');
    const [isSidebarOpen, setSidebarOpen] = useState(true); 
    const sidebarRef = useRef(null);
    const [refreshProfilePicture, setRefreshProfilePicture] = useState(false); // State to trigger profile picture refresh

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    const toggleSidebar = () => {
        if (window.innerWidth <= 768) {
            setSidebarOpen((prevState) => !prevState); 
        }
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (isSidebarOpen && sidebarRef.current && !sidebarRef.current.contains(event.target)) {
                if (window.innerWidth <= 768) {
                    setSidebarOpen(false); 
                }
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isSidebarOpen]);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > 768) {
                setSidebarOpen(true); 
            } else {
                setSidebarOpen((prevState) => (prevState ? prevState : false));
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    if (!username) {
        return <div className="error-message">User not logged in.</div>;
    }

    // Function to trigger profile picture refresh
    const handleProfilePictureUpdate = () => {
        setRefreshProfilePicture(prev => !prev); // Toggle state to refresh
    };

    const userId = localStorage.getItem('userId'); // Retrieve userId from localStorage

    return (
        <div className="dashboard-container">
            {/* Hamburger Menu for Mobile */}
            {window.innerWidth <= 768 && (
                <div className="hamburger" onClick={toggleSidebar}>
                    ☰
                </div>
            )}
    
            {/* Sidebar Component */}
            <Sidebar 
                ref={sidebarRef}
                activeTab={activeTab}
                handleTabClick={handleTabClick}
                handleLogout={handleLogout}
                username={username}
                points={points}
                balance={balance}
                isOpen={isSidebarOpen}
                toggleSidebar={toggleSidebar}
                refreshProfilePicture={refreshProfilePicture}
            />
    
            {/* Main Content Area */}
            <div className={`main-content ${isSidebarOpen ? '' : 'sidebar-closed'}`}>
                <div className="tab-content">
                    {activeTab === 'EditDash' && (
                        <EditDash onProfilePictureUpdate={handleProfilePictureUpdate} />
                    )}
                    {activeTab === 'normal' && <HostComputers />}
                    {activeTab === 'vip' && <HostVipComputers />}
                    {activeTab === 'blank' && <BlankContent />}
                </div>
            </div>
        </div>
    );
};

export default Welcome;
