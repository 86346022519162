import React, { useEffect, useState } from 'react';
import { buildApiUrl, getAuthHeaders } from '../utils/api';
import UserPoints from './UserPoints';
import UserBalance from './UserBalance';

const ProfilePicture = ({ username, refresh }) => {
    const [profilePicUrl, setProfilePicUrl] = useState('');
    const [loading, setLoading] = useState(true);
    const userId = localStorage.getItem('userId'); // Get user ID from session storage

    useEffect(() => {
        const fetchUserProfilePicture = async () => {
            if (!userId) return;

            setLoading(true);
            const apiUrl = buildApiUrl(`/users/${userId}/picture`);
            try {
                const response = await fetch(apiUrl, { headers: getAuthHeaders() });
                if (!response.ok) throw new Error('Error fetching user picture');

                const contentType = response.headers.get('content-type');
                if (contentType?.includes('application/json')) {
                    setProfilePicUrl('https://merrouchgaming.com/placeholder.jpg');
                } else {
                    const blob = await response.blob();
                    if (blob.size === 0) {
                        setProfilePicUrl('https://merrouchgaming.com/placeholder.jpg');
                    } else {
                        const imgUrl = URL.createObjectURL(blob);
                        setProfilePicUrl(imgUrl);
                    }
                }
            } catch (error) {
                console.error('Error fetching user picture:', error);
                setProfilePicUrl('https://merrouchgaming.com/placeholder.jpg');
            } finally {
                setLoading(false);
            }
        };

        fetchUserProfilePicture();
    }, [userId, refresh]); // Added `refresh` to dependencies

    return (
        <div>
            {loading ? (
                <img src="https://merrouchgaming.com/loading-spinner.gif" alt="Loading..." />
            ) : (
                <img
                    id="profilePicture"
                    src={profilePicUrl}
                    alt="User Profile"
                    style={{ width: '120px', height: '120px', borderRadius: '50%' }}
                />
            )}
            {userId && (
                <>
                    <UserPoints userId={userId} /> {/* Include UserPoints component */}
                    <UserBalance userId={userId} /> {/* Include UserBalance component */}
                </>
            )}
        </div>
    );
};

export default ProfilePicture;
