import React, { useEffect, useState } from 'react';
import { fetchUserById, fetchActiveUserSessions, fetchUserBalance } from '../utils/api';
import './HostVIPComputers.css';

const HostVipComputers = () => {
    const [computers, setComputers] = useState([]);

    useEffect(() => {
        const computersList = [
            { number: 9, id: 21 },
            { number: 10, id: 22 },
            { number: 11, id: 25 },
            { number: 12, id: 20 },
            { number: 13, id: 24 },
            { number: 14, id: 23 },
        ];

        const fetchComputersData = async () => {
            const activeSessions = await fetchActiveUserSessions();
            const updatedComputers = await Promise.all(computersList.map(async (computer) => {
                const session = activeSessions.find(s => s.hostId === computer.id);
                let timeLeft = null;

                if (session) {
                    const user = await fetchUserById(session.userId);
                    if (user) {
                        const balance = await fetchUserBalance(user.id);
                        timeLeft = balance; // Store the user's available time
                    }
                }

                return {
                    number: computer.number,
                    id: computer.id,
                    isActive: !!session,
                    timeLeft: timeLeft || 'No Time' // Default message if no time
                };
            }));

            setComputers(updatedComputers); // Update state
        };

        fetchComputersData(); // Initial fetch
        const intervalId = setInterval(fetchComputersData, 8000); // Fetch every 8 seconds

        return () => clearInterval(intervalId); // Cleanup on unmount
    }, []); // Runs once on mount

    return (
        <div id="computerGrid" className="computer-grid">
            {computers.map(computer => {
                const timeParts = computer.timeLeft && computer.timeLeft !== 'No Time'
                    ? computer.timeLeft.split(' : ') // Split the time string
                    : [0, 0]; // Default to 0h : 0min

                const hours = parseInt(timeParts[0]) || 0;
                const minutes = parseInt(timeParts[1]) || 0;
                const totalMinutes = hours * 60 + minutes; // Calculate total minutes

                return (
                    <div key={computer.id} className={`pc-square ${computer.isActive ? (totalMinutes < 60 ? 'orange' : 'active') : 'inactive'}`}>
                        <strong>PC{computer.number}</strong><br />
                        {computer.isActive ? `Active - Time Left: ${computer.timeLeft}` : 'No User'}
                    </div>
                );
            })}
        </div>
    );
};

export default HostVipComputers;
